<template>
  <template v-if="deviceType === 'desktop'">
    <div class="row">
      <div class="col-2 fm-navbar">
        <img class="img-fluid fm-image" src="../../public/images/funenglish-logo-white.svg" v-on:click="redirectToFunBook()">
      </div>
      <div class="col-3 fm-navbar"></div>
      <template v-if="isProxyUser">
        <div class="col-2 fm-navbar" style="background-color: red; font-size: 1rem">
          Jesteś zalogowany jako proxy user
        </div>
      </template>
      <template v-else>
        <div class="col-2 fm-navbar"></div>
      </template>
      <div class="col-1 fm-navbar text-center">
        <a id="navbar-option-0"
           href="/code"
           class=" fm-navbar-option fm-navbar-inactive-option"
           v-on:click="setActiveNavbarOption('navbar-option-0')">Aktywuj kupon</a>
      </div>
      <div class="col-1 fm-navbar text-center">
        <a id="navbar-option-1"
           href="/funbook"
           class=" fm-navbar-option fm-navbar-inactive-option"
           v-on:click="setActiveNavbarOption('navbar-option-1')">FunBook</a>
      </div>
      <div class="col-1 fm-navbar text-center">
        <a id="navbar-option-2"
           href="/dictionary"
           class="fm-navbar-option fm-navbar-inactive-option"
           v-on:click="setActiveNavbarOption('navbar-option-2')">Słownik</a>
      </div>
      <div class="col-1 fm-navbar text-center">
        <a id="navbar-option-3"
           href="/profile"
           class="fm-navbar-option fm-navbar-inactive-option"
           v-on:click="setActiveNavbarOption('navbar-option-3')">Moje konto</a>
      </div>
      <div class="col-1 fm-navbar"></div>
    </div>
  </template>

  <template v-if="deviceType === 'mobile'">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 fm-navbar">
        <div class="row text-start text">
          <div class="col-2 text-center">
            <slot name="left-navbar-part"></slot>
          </div>
          <div class="col-8">
            {{ title }}
          </div>
          <div class="col-2">
            <a data-bs-toggle="offcanvas" href="#offcanvasExample" aria-controls="offcanvasExample">
              <i class="bi bi-list fm-list-menu"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>

    <Menu></Menu>

    <slot name="left-canvas"></slot>
  </template>

</template>

<script>
import Menu from './Menu';
import { CookieService } from '../services/cookieService';
export default {
  name: 'Navbar',
  components: { Menu },
  props: {
    title: String
  },
  data() {
    return {
      deviceType: this.setDeviceType(),
      isProxyUser: false
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();

    if (this.deviceType === 'desktop') {
      this.setActiveNavbarOption();
    }

    this.isProxyUser = !!CookieService.getCookie('realToken');
  },
  unmounted() {
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
      return this.deviceType;
    },
    // TODO: This method should be unified with same method in Menu component
    setActiveNavbarOption() {
      const fullPath = this.$route.fullPath;
      const splittedFullPath = fullPath.split('/');
      const firstPartOfFullPath = splittedFullPath[1].toLowerCase();
      const activeNavbarElement = document.querySelector('a[href^="/' + firstPartOfFullPath + '"]');

      if (activeNavbarElement) {
        activeNavbarElement.classList.remove('fm-navbar-inactive-option');
        activeNavbarElement.classList.add('fm-navbar-active-option');
      }
    },
    redirectToFunBook() {
      this.$router.push('/funbook');
    }
  }
};
</script>

<style scoped>
  .fm-list-menu {
    color: #F8F9FA;
  }

  .fm-navbar-option {
    font-size: 1rem;
    font-weight: 700;
    padding-top: 1.25rem;
    text-decoration: none;
  }

  .fm-navbar-option:hover {
    cursor: pointer;
  }

  .fm-navbar-active-option {
    color: white;
  }

  .fm-navbar-inactive-option {
    color: rgba(255, 255, 255, 0.55);
  }

  .fm-image {
    cursor: pointer;
  }
</style>
