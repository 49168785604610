<template>
  <div class="offcanvas offcanvas-end w-75" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title fm-menu-title" id="offcanvasExampleLabel">Menu</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">

      <nav class="navbar  ">
        <div class="container">
          <div class="w-100 text-start" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link fm-navbar-item-inactive p-2" v-on:click="setActiveMenuItem('funbook')" id="funbook" href="/funbook">FunBook</a>
              </li>
              <li class="nav-item">
                <a class="nav-link fm-navbar-item-inactive p-2" v-on:click="setActiveMenuItem('dictionary')" id="dictionary" href="/dictionary">Słownik</a>
              </li>
              <li class="nav-item">
                <a class="nav-link fm-navbar-item-inactive p-2" v-on:click="setActiveMenuItem('profile')" id="profile" href="/profile">Mój profil</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  mounted() {
    this.setActiveMenuItem();
  },
  methods: {
    setActiveMenuItem() {
      const fullPath = this.$route.fullPath;
      const splittedFullPath = fullPath.split('/');
      const firstPartOfFullPath = splittedFullPath[1];
      const activeNavbarElement = document.querySelector('a[href^="/' + firstPartOfFullPath + '"]');

      if (activeNavbarElement) {
        activeNavbarElement.classList.remove('fm-navbar-item');
        activeNavbarElement.classList.add('fm-navbar-item-active');
      }
    }
  }
};
</script>

<style scoped>
  .fm-menu-title {
    color: var(--navy-blue);
    font-size: 1.5rem;
    font-weight: bold;
  }

  .fm-navbar-item-inactive {
    color: var(--royal-orange);
  }

  .fm-navbar-item-active {
    background-color: var(--royal-orange);
    color: white;
  }
</style>
