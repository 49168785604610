<template>
  <template v-if="deviceType === 'desktop'">
    <div class="container-fluid fm-background-main-color min-vh-100">
      <Navbar v-bind:title="'Moje konto'"></Navbar>

      <div class="row">
        <div class="col-4"></div>
        <div class="col-4 mt-5">
          <div class="card fm-desktop-card">
            <div class="card-body text-start">
              <h5 class="card-title fm-label-main-color fm-desktop-card-title text-start">Konto</h5>
              <h6 class="card-subtitle mb-2 text-muted text-start mt-4">Nazwa konta</h6>
              <p class="card-title fm-label-main-color fm-desktop-card-login text-start">{{ user ? user.username : null}}</p>
              <a href="/change-password" class="fm-desktop-link">Zmień hasło</a><br/>
              <a href="#" class="fm-desktop-link" data-bs-toggle="modal" data-bs-target="#exampleModal">Wyloguj się</a>
            </div>
          </div>
        </div>
        <div class="col-4"></div>
      </div>

      <div class="row">
        <div class="col-4"></div>
        <div class="col-4 mt-5">
          <div class="card fm-desktop-card">
            <div class="card-body">
              <h5 class="card-title fm-label-main-color fm-desktop-card-title text-start">Dostęp</h5>
              <h6 class="card-subtitle mb-2 text-muted text-start mt-4">Dostęp zakończy się:</h6>
              <p class="card-title fm-label-main-color fm-desktop-card-login text-start">{{ access ? access.expiredAt : 'Nie posiadasz aktywnego dostępu'}}</p>
              <button class="btn btn-primary w-100 fm-button-main-background-color fm-desktop-button" v-on:click="this.$router.push('/pricing')">
                Przedłuż dostęp
              </button>
            </div>
          </div>
        </div>
        <div class="col-4"></div>
      </div>

      <div class="modal fade fm-logout-modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Wyloguj się</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Czy na pewno chcesz się wylogować?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary fm-logout-close-button" data-bs-dismiss="modal">Anuluj</button>
              <button v-on:click="logout" type="button" class="btn btn-primary fm-button-main-background-color" data-bs-dismiss="modal">Ok</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </template>

  <template v-if="deviceType === 'mobile'">
    <div class="container fm-background-main-color min-vh-100">

      <Navbar v-bind:title="'Moje konto'"></Navbar>

      <div class="row text-start fm-my-account-main-label-row">
        <div class="col-12">
          <span class="fm-label-main-color fm-main-label">Konto</span>
        </div>
      </div>

      <div class="row fm-first-card-padding">
        <div class="col-12">
          <div class="card">
            <div class="card-body text-start fm-card">
              <h6 class="card-subtitle mb-2 text-muted">Nazwa konta</h6>
              <h5 class="card-title fm-label-main-color fm-my-account-username">{{ user ? user.username : null}}</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 ">
          <a class="btn btn-outline-primary fm-button-main-border-color w-100 fm-my-account-button" href="/change-password" role="button">
            Zmień hasło
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-12 ">
          <button type="button" class="btn btn-outline-primary fm-button-main-border-color w-100 fm-my-account-button" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Wyloguj się
          </button>
        </div>
      </div>

      <div class="row text-start fm-my-account-main-label-row">
        <div class="col-12">
          <span class="fm-label-main-color fm-main-label">Subskrypcja</span>
        </div>
      </div>

      <!--    <div class="row fm-subscription-card fm-first-card-padding">-->
      <!--      <div class="col-12">-->
      <!--        <div class="card">-->
      <!--          <div class="card-body text-start fm-card">-->
      <!--            <h6 class="card-subtitle mb-2 text-muted">Subskrypcja rozpoczęła się:</h6>-->
      <!--            <h5 class="card-title fm-label-main-color fm-my-account-username">12.02.2022</h5>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </div>-->

      <div class="row fm-subscription-card fm-card-padding">
        <div class="col-12">
          <div class="card">
            <div class="card-body text-start fm-card">
              <h6 class="card-subtitle mb-2 text-muted">Dostęp zakończy się:</h6>
              <h5 class="card-title fm-label-main-color fm-my-account-username">{{ access ? access.expiredAt : 'Nie posiadasz aktywnego dostępu'}}</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <button class="btn btn-primary w-100 fm-button-main-background-color fm-button">Przedłuż<br> dostęp</button>
        </div>
      </div>

      <div class="modal fade fm-logout-modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Wyloguj się</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Czy na pewno chcesz się wylogować?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary fm-logout-close-button" data-bs-dismiss="modal">Anuluj</button>
              <button v-on:click="logout" type="button" class="btn btn-primary fm-button-main-background-color" data-bs-dismiss="modal">Ok</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </template>

</template>

<script>
import Navbar from '../components/Navbar';
import cookieService from '../services/cookieService';
import UserService from '../services/userService';
import AccessService from '../services/accessService';
export default {
  name: 'Profile',
  components: { Navbar },
  computed: {
    isUserLoggedIn: function() {
      return this.$store.state.isUserLoggedIn;
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();

    this.initializeView();
  },
  unmounted() {
    window.addEventListener('resize', this.onResize);
  },
  data() {
    return {
      user: {
        id: null,
        username: null
      },
      access: {
        id: null,
        expiredAt: null
      },
      deviceType: 'desktop'
    };
  },
  methods: {
    async initializeView() {
      await this.getUserData();
      await this.getAccessData();
    },
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
    },
    async logout() {
      const realToken = cookieService.getCookie('realToken');

      if (realToken) {
        cookieService.setCookie('realToken', null);
        cookieService.setCookie('token', realToken);

        await this.$router.push('admin');
      } else {
        cookieService.setCookie('token', null);
        this.$store.commit('setIsUserLoggedIn', false);
        await this.$router.push('Login');
      }
    },
    async getUserData() {
      const response = await UserService.getUserByToken();
      const userData = response.data.users.items[0];

      this.user.username = userData.username;
      this.user.id = userData.id;
    },
    async getAccessData() {
      const response = await AccessService.getAccessData(this.user.id);
      const accessData = response.data.access.items[0];

      this.access.expiredAt = accessData.expiredAt;
    }
  }
};
</script>

<style scoped>
.fm-my-account-main-label-row {
  margin-top: 1.5rem;
}

.fm-my-account-username {
  font-size: 1.25rem;
  font-weight: bold;
}

.fm-my-account-button {
  margin-top: 1rem;
}

.fm-card {
 padding-bottom: 0.5rem;
}

.fm-first-card-padding {
  margin-top: 1.5rem;
}

.fm-card-padding {
  margin-top: 1rem;
}

.fm-button {
  margin-top: 1.5rem;
  height: 9rem;
  font-size: 1.75rem;
}

.fm-logout-close-button {
  background-color: var(--royal-orange);
  border-color: var(--royal-orange);
}

.fm-logout-modal {
  margin-top: 30vh;
}

.fm-desktop-card {
  border-radius: 2rem;
  padding-left: 1rem;
}

.fm-desktop-card-title {
  font-size: 1.75rem;
  font-weight: 600;
}

.fm-desktop-card-login {
  font-size: 1.25rem;
  font-weight: 700;
}

.fm-desktop-link {
  text-decoration: none;
  color: var(--royal-orange);
  cursor: pointer;
}

.fm-desktop-button {
  border-radius: 0.75rem;
  font-size: 1.25rem;
  font-weight: 600;
  height: 4rem;
  margin-top: 1.5rem;
}
</style>
