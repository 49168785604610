import cookieService from './cookieService';
import axios from 'axios';
import requestService from './requestService';

export class UserService {
  static async getUserList(page = 1, limit = 10000, userIdentity = null) {
    let path = process.env.VUE_APP_DOMAIN + '/rest/user/users?';
    path = page ? path + '&page=' + page : path;
    path = limit ? path + '&limit=' + limit : path;
    path = userIdentity ? path + '&userIdentity=' + userIdentity : path;

    return requestService.makeGetRequest(path);
  }

  static async getUserByUserId(userId) {
    const path = '/rest/user/users/id/' + userId;

    return requestService.makeGetRequest(path);
  }

  static async getUserByToken() {
    const path = '/rest/user/users/token';

    return requestService.makeGetRequest(path);
  }

  static async loginAsUserBySalt(salt) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/login/salt',
      {
        loginSalt: salt
      },
      config
    );
  }

  static async changePassword(
    password = null,
    passwordResetToken = null
  ) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/user/users/password',
      {
        password: password,
        passwordResetToken: passwordResetToken
      },
      config
    );
  }

  static async editUser(userId, email, username, termsAndConditionsAgreement, marketingAgreement, password = null) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    return await axios.put(
      process.env.VUE_APP_DOMAIN + '/rest/user/users/' + userId,
      {
        email: email,
        username: username,
        password: password,
        terms_and_conditions_agreement: termsAndConditionsAgreement,
        marketing_agreement: marketingAgreement
      },
      config
    );
  }

  static async addManyUsers(usersData) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/user/users/accounts',
      {
        generatingMethod: 'emails',
        users: usersData
      },
      config
    );
  }
}

const userService = UserService;
export default userService;
