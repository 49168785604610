import requestService from './requestService';
import cookieService from './cookieService';
import axios from 'axios';

export class AccessService {
  static async getAccessData(userId) {
    const path = '/rest/access/user/id/' + userId;

    return requestService.makeGetRequest(path);
  }

  static async updateUserAccess(userId, courseId, daysNumber, expiredAt) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/access/user',
      {
        userId: userId,
        courseId: courseId,
        daysNumber: daysNumber,
        expiredAt: expiredAt
      },
      config
    );
  }
}

const accessService = AccessService;
export default accessService;
